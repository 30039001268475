.video-background {
	min-width: 100%;
	min-height: 100%;
	width: 100%;
	height: auto;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: -100;
	background-size: cover;
}

@media (min-aspect-ratio: 16/9) {
	.video-background {
		width: 100%;
		height: auto;
	}
}

@media (max-aspect-ratio: 16/9) {
	.video-background {
		width: auto;
		height: 100%;
	}
}

@media (max-width: 767px) {
	.video-background {
		display: none;
	}
	body {
		background-image: url('/img/mobil.png');
		background-color: #cccccc;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
